import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["loader"];

  connect() {
    // Listen for Turbo events
    document.addEventListener(
      "turbo:before-fetch-request",
      this.handleBeforeFetchRequest.bind(this)
    );
    document.addEventListener("turbo:before-cache", this.hideLoader.bind(this));
    document.addEventListener("turbo:load", this.handlePageLoad.bind(this));
  }

  handleBeforeFetchRequest(event) {
    // Check if the request is targeting a Turbo Frame
    if (!event.detail.fetchOptions.headers["Turbo-Frame"]) {
      // It's a full page load
      this.showLoader(); // Show loader
    }
  }

  hideLoader() {
    this.loaderTarget.style.display = "none"; // Hide the loader
  }

  handlePageLoad() {
    // Hide the loader after the page loads
    this.hideLoader();
  }

  showLoader() {
    this.loaderTarget.style.display = "block"; // Show the loader
  }
}
